const priorities = [
  {
    rank: 0,
    name: "تحسين المستوى المعيشي للمواطن",
    description:
      "سأقوم بتبني مشروع إقرار حزمة من القوانين المعنية بزيادة رواتب الموظفين والمتقاعدين، والقرض الحسن للمتقاعدين، والضغط على الحكومة لإيقاف مشاريع قوانين ضريبة القيمة المضافة، وضريبة الدخل على المواطنين، ومساءلة الوزراء المختصين على زيادة الرسوم على المواطن أو رفع الدعوم عن الشرائح المستحقة من الطبقة المتوسطة ودونها.",
  },
  {
    rank: 1,
    name: "ترشيد نفقات الوزراء وكبار المسؤولين",
    description:
      "سأعمل على أن يراجع رئيس الوزراء الرواتب الاستثنائية الممنوحة للطاقم الوزاري وكبار المسؤولين، ومساءلة الوزراء والوكلاء عن النفقات والمصاريف غير الضرورية في مؤسسات الدولة، لاسيما مكافآت اللجان غير المنتجة، ورحلات العمل الخارجية ذات الطابع التنفيعي، والدورات الخارجية غير المرتبطة بالمهام المباشرة للعمل.",
  },
  {
    rank: 2,
    name: "تسكين المناصب القيادية",
    description:
      "لإنهاء حالة الفراغ الإداري، والبلد الذي يدار بالتكيف منذ فترة طويلة، سألزم الحكومة بتسكين المناصب القيادية من شريحة الشباب من أصحاب الاختصاص والشهادات العلمية المتميزة والقدرات الإدارية، لمحاربة الشلل الإداري الذي تعاني منه الدولة، ومساءلة الحكومة على استثناء تعيينات القياديين على أساس نوع الجنسية أو الطائفة أو الخلفية الاجتماعية أو التوجه السياسي.",
  },
  {
    rank: 3,
    name: "تطوير نظام الدوام المرن",
    description:
      "سأدفع الحكومة لإقرار نظام للدوام المرن الذي يراعي أوقات الطلبة وأولياء أمورهم، لمراعاة المواطنين، ومحاربة ظاهرة الازدحام المروري، والتسهيل على قاطني المناطق البعيدة، وتطوير النظم الإدارية لتخفيف الضغوط على قاطني المدن الإسكانية الجديدة.",
  },
  {
    rank: 4,
    name: "إقرار نظام دوام الأربعة أيام للأمهات",
    description:
      "لأنني أعتقد بأن الأسرة نواة المجتمع، لذلك سأعمل على منح الأمهات وقتاً مناسباً لتربية أبنائهم، عن طريق إقرار نظام دوام الأربعة أيام في الوظائف الإدارية التي لا تستلزم استقبال مراجعين.",
  },
  {
    rank: 5,
    name: "إقرار البعثات الدراسية للقطاع الخاص",
    description:
      "سأعمل على تطوير نظام الابتعاث إلى الخارج، ونظام البعثات الداخلية، ليشمل موظفي القطاع الخاص كميزة لمن يتجه إليه، في فترات تحددها الدولة وبالتخصصات التي يحتاجها سوق العمل.",
  },
  {
    rank: 6,
    name: "منح ربة المنزل راتباً معقولاً",
    description:
      "سأسعى لتحسين دخل ربات المنازل، وتطوير آلية حوافز ترتبط بأداء أسرهم كالأداء الدراسي للأبناء، وفتح مجال العمل التطوعي في اللجان الخيرية، ومؤسسات المجتمع المدني المرخصة من الدولة.",
  },
  {
    rank: 7,
    name: "تطوير النظام الانتخابي",
    description:
      "لأنني أؤمن أن العمل الجماعي السياسي يؤدي إلى تماسك البرلمان، وتكوين كتل برلمانية ذات أولويات مشتركة، سأتبنى نظام انتخابي يعزز خيارات الناخبين، والتسوية بين المختلفين سياسياً.",
  },
  {
    rank: 8,
    name: "تعزيز الحريات العامة",
    description:
      "سأدعم الحريات قولاً وممارسة، لأنني أرى أن وجود مساحة للحريات العامة تتناسب مع الثقافة الكويتية تؤدي إلى الاستقرار السياسي، لاسيما حرية الرأي والتعبير وحرية ممارسة الشعائر الدينية بما لا يتعارض مع النظام العام للدولة.",
  },
  {
    rank: 9,
    name: "تطوير النقل العام",
    description:
      "سأقترح تشريعات وقوانين من شأنها تطوير خدمات النقل العام عبر خلق منافسة في السوق بين القطاعين الخاص والعام في هذا المجال، وتطوير هذه الوسيلة لتكون ملائمة لفئات أكثر من المجتمع، وتخفيف العبء المروري في الطرق العامة.",
  },
];
export default priorities;
