import React from "react";
import slogan from "../assets/images/2024-hamad.svg";
import cities from "../data/cities";
function Footer() {
  return (
    <section className="bg-primary p-5 border-t border-secondary flex flex-col items-center [&>*]:text-white">
      <img className="w-[100px] h-auto mb-3" src={slogan} alt="شعار 2024" />

      <span className="text-center font-bold">
        {cities.map((el) => el.name).join(" - ")}
      </span>
    </section>
  );
}

export default Footer;
