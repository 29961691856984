import React from "react";

function Resume() {
  return (
    <section
      id="resume"
      className="scroll-mt-[60px] bg-primary p-5 border-t border-secondary flex flex-col [&>*]:text-white"
    >
      <h2 className="text-3xl font-bold text-center mb-4 md:text-5xl">
        السيرة الذاتية
      </h2>
      <div className="md:grid md:grid-cols-3 md:gap-10 md:text-2xl">
        <div>
          <h3 className="text-secondary font-bold text-right text-2xl md:text-3xl">
            الخبرة العملية
          </h3>
          <ul className="list-disc mr-5">
            <li>خبير هندسي - الادارة العامة للخبراء</li>
            <li>
              عضو هيئة تدريب منتدب جزئي - الهيئة العامة للتعليم التطبيقي
              والتدريب
            </li>
            <li>مهندس مدني - شركة أميك فوستر ويلر</li>
            <li>مهندس - وزارة الأشغال العامة</li>
          </ul>
        </div>
        <div>
          <h3 className="text-secondary font-bold text-right text-2xl md:text-3xl">
            الشهادات العلمية
          </h3>
          <ul className="list-disc mr-5">
            <li>
              ماجستير الهندسة الإنشائية - جامعة ليدز بالمملكة المتحدة 2014
            </li>
            <li>
              بكالوريوس الهندسة المدنية - جامعة برادفورد بالمملكة المتحدة 2013
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-secondary font-bold text-right text-2xl md:text-3xl">
            النشاط النقابي
          </h3>
          <ul className="list-disc mr-5">
            <li>
              مقرر اللجنة الدستورية بالهيئة التنفيذية بالاتحاد الوطني لطلبة
              الكويت 2013\2015
            </li>
            <li>
              رئيس الاتحاد الوطني لطلبة الكويت - فرع المملكة المتحدة وايرلندا
              2010\2011
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Resume;
