const cities = [
  {
    order: 0,
    name: "المرقاب",
  },
  {
    order: 0,
    name: "عبدالله السالم",
  },
  {
    order: 0,
    name: "القبلة",
  },
  {
    order: 0,
    name: "الشويخ",
  },
  {
    order: 0,
    name: "الشامية",
  },
  {
    order: 0,
    name: "القادسية",
  },
  {
    order: 0,
    name: "المنصورية",
  },
  {
    order: 0,
    name: "الفيحاء",
  },
  {
    order: 0,
    name: "النزهة",
  },
  {
    order: 0,
    name: "الصليبيخات",
  },
  {
    order: 0,
    name: "الدوحة",
  },
  {
    order: 0,
    name: "غرناطة",
  },
  {
    order: 0,
    name: "القيروان",
  },
  {
    order: 0,
    name: "جابر الأحمد",
  },
  {
    order: 0,
    name: "النهضة",
  },
];

export default cities;
