import xIcon from "../assets/images/x-social-media-icon.png";
import instagramIcon from "../assets/images/instagram-icon.svg";
import tiktokIcon from "../assets/images/tiktok-social-media-icon.png";
import alanbaIcon from "../assets/images/alanba-logo.png";

const links = [
  { name: "اكس", link: "https://x.com/hamadhomud", icon: xIcon },
  {
    name: "انستغرام",
    link: "https://instagram.com/hamadhomud",
    icon: instagramIcon,
  },
  {
    name: "تكتوك",
    link: "https://tiktok.com/@hamadhomud",
    icon: tiktokIcon,
  },
  {
    name: "جريدة الأنباء",
    link: "https://www.alanba.com.kw/author/hamadhammoud",
    icon: alanbaIcon,
  },
];
export default links;
