import React from "react";
import hamadImage from "../assets/images/hamad-alshammari-image.jpg";
import slogan from "../assets/images/2024-hamad.svg";
import majoritySlogan from "../assets/images/majority-slogan.svg";
function FirstFold() {
  return (
    <section
      id="intro"
      className="w-full bg-primary flex flex-col mt-[60px] md:h-screen md:flex-row"
    >
      <img
        className="h-[350px] object-cover md:w-[50%] md:h-[100%]"
        src={hamadImage}
        alt="صورة حمد الشمري"
      />
      <div className="flex flex-col w-full justify-center items-center [&>*]:mb-5 h-[300px] md:w-[50%] md:h-[100%]">
        <div className="w-full text-center p-4 [&>*]:text-white [&>*]:mb-3">
          <h1 className="text-4xl font-bold md:text-6xl">م. حمد حمود الشمري</h1>
          <h3 className="text-xl md:text-3xl">أمة 2024 - الدائرة الثانية</h3>
        </div>
        <img className="w-[200px] h-auto" src={slogan} alt="شعار 2024" />
        <img
          className="w-[200px] h-auto"
          src={majoritySlogan}
          alt="نحن الأغلبية"
        />
      </div>
    </section>
  );
}

export default FirstFold;
