import React from "react";

function Statement() {
  return (
    <section
      id="statement"
      className="scroll-mt-[60px] bg-primary p-5 border-t border-secondary flex flex-col [&>*]:text-white"
    >
      <h2 className="text-3xl md:text-5xl font-bold text-center mb-4">
        بيان خوض الانتخابات
      </h2>
      <div className="[&>p]:mb-3 [&>p]:text-justify md:w-[500px] md:mx-auto">
        <h3 className="text-xl font-bold text-center mb-4">
          بسم الله الرحمن الرحيم
        </h3>
        <p className="md:text-2xl">
          إِنْ أُرِيدُ إِلَّا الْإِصْلَاحَ مَا اسْتَطَعْتُ وَمَا تَوْفِيقِي
          إِلَّا بِاللَّهِ عَلَيْهِ تَوَكَّلْتُ وَإِلَيْهِ أُنِيبُ (هود 88)
        </p>
        <p className="md:text-2xl">
          نعيش حالة غير مسبوقة من غياب العدالة الاجتماعية واللامساواة سببها
          قرارات غير مسؤولة من حكومات فشلت في حل قضايانا التي تمس مستوى معيشتنا.
        </p>
        <p className="md:text-2xl">
          هذا الفشل أنتج جيلاً كاملاً حُرمَ من أبسط حقوقه كالسكن الملائم
          والوظيفة المناسبة، حتى أصبح أقصى طموحاتنا لا أن يصبح وطننا بمصافِ دول
          الجوار، بل أن يُوفرَ لنا وأسرنا احتياجاتنا اليومية.
        </p>
        <p className="md:text-2xl">
          أخوض انتخابات مجلس الأمة 2024 ممثلاً الدائرة الثانية تحت شعار نحن
          الأغلبية، لأننا{" "}
          <span className="text-secondary font-bold">أغلبية الشعب</span>،
          <span className="text-secondary font-bold"> وأقلية الثروة</span>،
          وأخوضها لأننا أغلبية العطاء، وأقلية المردود، وأخوضها لأنه لم يعد هناك
          متسع من الوقت لأن يقامر المجلس والحكومة بمستقبلنا تحت ذريعة الإصلاح
          الاقتصادي والتعاون بين السلطات.
        </p>
        <p className="md:text-2xl"> وأسأل الله التوفيق والصلاح وخير المسير.</p>
      </div>
    </section>
  );
}

export default Statement;
